.testslider {
  @media (max-width: 1199px) {
    margin: 60px 0 0;
  }
  @media (max-width: 575px) {
    margin: 0px 0 0;
  }
  .tstgrpbtm {
    display: flex;
    margin: 120px auto 0;
    justify-content: center;
    @media (max-width: 575px) {
      flex-direction: column;
    }
    .srchButton {
      border-radius: 5px;
      background: linear-gradient(90deg, rgb(201, 59, 84) 27%, rgb(146, 27, 152) 72%);
      padding: 5px 8px;
      min-width: 200px;
      border: 0;
      color: #fff;
      cursor: pointer;
      margin: 0 15px;
      text-align: center;
      @media (max-width: 575px) {
        margin: 0 auto 15px;
      }
    }
  }
  .tssliderrow {
    width: 100%;
    padding: 30px;
    background-color: var(--matterColorLight);
    display: inline-flex !important;
    @media (max-width: 575px) {
      flex-direction: column;
    }

    .tsslidercol {
      padding: 0 5% 0 0;
      max-width: 60%;
      flex-basis: 60%;
      @media (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      img {
        height: 80px;
      }
      p {
        font-size: 18px;
        color: #232323;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      h2 {
        margin: 50px 0 0;
        color: #232323;
        font-weight: 700;
        @media (max-width: 767px) {
          font-size: 16px;
        }
        @media (max-width: 575px) {
          margin: 20px 0 0;
        }
      }
    }
    .tsslidercolimg {
      max-width: 40%;
      flex-basis: 40%;
      @media (max-width: 575px) {
        max-width: 100%;
        flex-basis: 100%;
        margin: 20px 0 0;
      }
      img {
        height: 350px;
        object-fit: cover;
        width: 100%;
        @media (max-width: 575px) {
          min-height: auto;
        }
      }
    }
  }
}

.activityBenifits{
  background-color: transparent !important;
  margin:24px 0px -36px;
  display: flex;
  color:#000;

  & h3{
    max-width: 480px;
    font-size: 36px;
    line-height: 3rem;
    font-family: 'Stardos Stencil', cursive;
    font-weight: 500;
    margin:0 auto 24px;
  }
  & .benifitContent{
    display: flex;
    flex-direction: column;
    padding: 0 0px 0 50px;
    margin: auto;
    @media (max-width:767px) {
      padding: 20px 0px 0 0px;
    }

    & img{
      width: 240px;
      height:240px;
      object-fit: cover;
      border-radius: 8px;
      margin-right:32px;
    }

    & > div:nth-child(2) > p:first-child{
      margin-top: 0px;
    }
    & > div:nth-child(2) > p:last-child{
      margin-bottom: 0px;
    }

    @media screen and (max-width:656px){
      flex-direction: column;
      align-items: center;

      & > div:nth-child(2) > p:first-child{
        margin-top: 16px;
      }

      & img{
        margin-right: 0px;
      }
    }
  }
}