.discoverImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1128px;
}
.images {
  max-width: 100%;
  border-radius: 30px;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(180deg, #921b98 27%, #c93b54 72%);
  padding: 2px;
  min-height: 260px;
  object-fit: cover;

  &:hover {
    transform: scale(1.02);
    /* box-shadow: var(--boxShadowSectionLocationHover); */
  }
  @media (max-width: 991px) {
    min-height: auto;
    /* max-height: 150px; */
  }
}
.imageButtons {
  flex-basis: 25%;
  padding: 20px 10px;
  border: 0px;
  @media (max-width: 991px) {
    flex-basis: 33%;
  }
  @media (max-width: 767px) {
    flex-basis: 50%;
  }
  @media (max-width: 575px) {
    flex-basis: 50%;
    padding-top: 0;
  }
}
.imageButtons span {
  font-size: 20px;
  color: #232323;
  margin: 10px 0 0;
  display: block;
  font-weight: 500;

  @media (max-width: 575px) {
    font-size: 16px;
  }
}
.discoverSection {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  & h2 {
    max-width: 1200px;
  }
  & h3 {
    margin-top: -20px;
  }
}

.listMyBusinessDiscoverContainer {
  width: 100%;
  max-width: 1026px;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #000;

  & .listMyBusinessDiscoverContent {
    flex-basis: calc(50% - 10px);
  }
  & .listMyBusinessDiscoverContent:nth-child(odd) {
    padding-right: 20px;
    flex-basis: calc(50% + 10px);
  }

  @media screen and (max-width: 577px) {
    flex-direction: column;

    & .listMyBusinessDiscoverContent {
      flex-basis: 100% !important;
      padding-right: 0px !important;
    }
  }
  @media (--viewportMedium) {
    & .listMyBusinessDiscoverContent {
      flex-basis: calc(50% - 20px);
    }
    & .listMyBusinessDiscoverContent:nth-child(odd) {
      padding-right: 40px;
      flex-basis: calc(50% + 20px);
    }
  }
}
