.sectionTopRead {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-bottom: 50px;
  text-align: center;
  max-width: 1128px;
  margin: 0 auto;
}
.sectionContainer {
  background-color: var(--matterColorLight);
  display: flex;
  text-align: center;
  flex-direction: column;
  margin: 0 15px;
  flex-basis: 31%;
  padding: 20px;
  max-width: 31%;
  @media (max-width: 767px) {
    flex-basis: 40%;
    max-width: 40%;
    margin: 0 15px 15px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
    margin: 0 0 15px;
  }
}
.hideImg {
  display: none;
}
.sectionContainer img {
  height: 350px;
  object-fit: cover;
  @media (max-width: 991px) {
    height: 250px;
  }
}
.sectionContainer span {
  color: #232323;
  display: block;
  margin: 20px 20px;
  line-height: 28px;
  font-size: 20px;
  @media (max-width: 991px) {
    margin: 20px 0;
    line-height: 22px;
    font-size: 16px;
  }
}
.topReadBody {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.bloga {
  display: inline-block;
}
.ourBlog {
  margin: 30px 0;
  background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  border: none;
  color: var(--matterColorLight);
  border-radius: 8px;
  cursor: pointer;
  padding: 6px 25px;
}
.sectionContainer2 p {
  font-size: 15px;
  line-height: 22px;
  text-align: left;
}
.sectionContainer2 .ourBlog {
  min-width: 40%;
}
.topReadBody2 {
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.topBody2title h2 {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  line-height: 60px;
  font-weight: 500;
  max-width: 60%;
  margin: 30px auto 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 767px) {
    font-size: 35px;
    line-height: 46px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
}
.sectionContainer2 {
  background-color: var(--matterColorLight);
  display: flex;
  flex-basis: 50%;
  text-align: justify;
  flex-direction: column;
  margin: 0 20px;
  padding: 30px;
  max-width: 50%;
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 0 15px;
  }
}
.sectionContainer3 {
  background-color: var(--matterColorLight);
  display: flex;
  max-width: 50%;
  text-align: justify;

  flex-direction: column;

  padding: 10px 10px;
}
.imgRow {
  background-color: var(--matterColorLight);
  display: flex;
  /* flex-wrap: wrap-reverse; */
  justify-content: center;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.img1 {
  margin: 5px 15px;
  flex-basis: 43%;
  border-radius: 30px;
  max-width: 43%;
  min-height: 180px;
  object-fit: cover;
  @media (max-width: 1199px) {
    max-width: 42%;
  }
  @media (max-width: 991px) {
    max-width: 45%;
    min-height: 110px;
    margin: 0 5px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 15px;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    margin: 0 0 15px;
  }
}
.img2 {
  margin: 5px 15px;
  flex-basis: 43%;
  border-radius: 30px;
  max-width: 43%;
  min-height: 180px;
  object-fit: cover;
  @media (max-width: 1199px) {
    max-width: 42%;
  }
  @media (max-width: 991px) {
    max-width: 45%;
    min-height: 110px;
    margin: 0 5px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 15px;
    display: none;
  }
  @media (max-width: 575px) {
    max-width: 100%;
    margin: 0 0 15px;
    display: none;
  }
}
.body2Title {
  display: flex;
  justify-content: center;
  margin: 20px 0 0;
  font-size: 26px;
  color: #232323;
  align-items: center;
  @media (max-width: 991px) {
    font-size: 18px;
  }
}

.body2Title2 {
  margin: 0 0 0 5px;
  font-weight: 700;
  color: #232323;
  font-size: 22px !important;
  @media (max-width: 991px) {
    font-size: 18px !important;
  }
}
.browseActivities {
  text-align: center;
  margin: auto 0 0;
}
.browseActivities button {
  margin-bottom: 0;
}
.textDivs {
  padding: 0 34px;
  @media (max-width: 575px) {
    padding: 0 !important;
  }
}
