@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --backgroundImage;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding: 0;
  flex-basis: 600px;
  margin: 0 auto;
  border: none;
}

/* ================ Tabs & Form ================ */

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.signupForm {
  margin-top: 24px;

  /* We don't want the form to take the whole space so that on mobile view
  the social login buttons will be after the sign up button
  and not in the bottom of the page.
  We also need to add flex-basis value so that Safari will show the
  whole form correctly.  */
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 30px;
    padding-top: 2px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.confirmInfoText {
  @apply --marketplaceH4FontStyles;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
.dv3 {
  @media (max-width: 767px) {
    box-shadow: 0px 0px 10px #cecece;
    padding: 30px 40px;
    margin-right: 30px;
    margin-left: 30px;
    & > p:first-child {
      margin-top: 0;
      color: #c93b54;
    }
    & > div {
      max-width: 85% !important;
    }
  }
}
.dv3 > div {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  & > div {
    margin-left: 15px;
  }
  & > div:first-child {
    margin-bottom: 15px;
    @media (min-width: 767px) {
      margin-bottom: 5px;
    }
  }
}
.dv3 input {
  display: none;
}
.dv3 label span:first-child {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    fill: #000;
    width: 15px;
    height: 15px;
  }
}
.dv3 label span:last-child {
  margin-left: 15px;
}
.dv3 label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
.dv3 p {
  color: #000;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}
.dvone p {
  font-size: 16px;
  text-align: center;
  line-height: 21px;
  width: 80%;
  margin: 15px auto;
  color: #000;
}
.dvone p span {
  padding-bottom: 2px;
  border-bottom: 1px solid #cecece;
}
.dvone p span:first-child {
  padding-bottom: 0;
  border-bottom: none;
  font-weight: 700;
}
.dvone > span {
  font-style: italic;
  font-size: 15px;
}
.dvone {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  @media (max-width: 767px) {
    display: none;
  }
}
.logswitch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  box-shadow: 0px 0px 10px #cecece;
  padding: 30px 40px;
  @media (max-width: 767px) {
    display: none;
  }
}
.verifiedCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 20px;
  box-shadow: 0px 0px 10px #cecece;
  padding: 20px 40px;
  gap: 8px;
  & > h1 {
    margin: 0;
    color: #000;
    font-family: 'Stardos Stencil', cursive;
    font-size: 32px;
    line-height: 45px;
  }
  & > p {
    margin: 0;
    color: #c93b54;
    font-size: 16px;
    font-weight: 700;
  }
  & > span {
    max-width: 220px;
    text-align: center;
    font-size: 16px;
  }
}
.logswitch a {
  padding: 0;
  justify-content: center;
}
.logswitch button {
  padding: 0;
  justify-content: center;
  width: 100%;
}
.logswitch p {
  font-family: 'Stardos Stencil', cursive;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 0;
  color: #000;
  text-align: center;
  line-height: 45px;
}
.logswitch p span {
  color: #c93b54;
}
.logswitch nav {
  width: 100%;
  justify-content: center;
  & > div {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.logswitch nav a h1 {
  text-align: center;
}
.logswitch a .selectedTabContent {
  display: none;
}
.tabrc .selectedTabContent {
  background: red;
}
.seltab {
  border-bottom: 2px solid #c93b54 !important;
}
.modcont {
  padding: 55px 60px;
  background: #fff;
  position: relative;
  @media (min-width: 575px) and (max-width: 767px) {
    padding-top: 20px;
  }
  @media (min-width: 767px) {
    border-radius: 10px;
  }
  @media (max-width: 575px) {
    padding: 20px 20px 40px;
  }
}
.modcont img {
  position: absolute;
  top: -71px;
  left: 223px;
  width: 130px;
  height: 130px;
  @media (max-width: 767px) {
    display: none;
  }
}
.modcntnr {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  padding: 0;

  @media (min-width: 767px) {
    border: 4px solid transparent;
    border-radius: 10px;
    margin-top: 14vh;
    background: linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%);
  }
  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}
/* .modcntnr > button:first-child{
  @media (max-width:767px) {
    display: none;
  }
} */
.tabMob {
  padding-top: 0;
  border: 0;
}
.mobileTabs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 34px;
  & a {
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: 0;
    color: var(--matterColor);
    border-bottom: 2px solid transparent;
    transition: var(--transitionStyleButton);
    padding: 0 10px;
  }
  @media (min-width: 768px) {
    display: none;
  }
}
.settabmob {
  color: #000 !important;
}
.fstbkp {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 50px;
  text-align: center;
  margin: 5px 0;
  @media (min-width: 768px) {
    display: none;
  }
}
.hide_fstbkp {
  display: none;
}
.authModal {
  & > div > div {
    @media (max-width: 767px) {
      margin-top: 2vh;
    }
  }
}
