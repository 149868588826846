@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 67.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }

  @media (max-width: 767px) {
    min-height: 636px;
    height: auto;
    max-height: none;
  }
  @media (max-width: 575px) {
    min-height: 460px;
  }
}
.btht {
  color: #000;
  position: absolute;
}
/* .threeIcons {
  background-color: #f3f4f5;
  display: flex;
  width: full;
  justify-content: center;
  align-items: center;
} */
.hero {
  flex-grow: 1;
  justify-content: flex-end;
  /* padding-bottom: 32px; */

  /* @media (--viewportMedium) {
    padding-bottom: 83px;
  } */

  @media (--viewportLarge) {
    justify-content: center;
    /* padding-top: 60px; */
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
  background: #f2f1ef;
}

.section {
  background-color: #f2f1ef;
  overflow: hidden;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 150px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 150px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 150px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
  @media (max-width: 1199px) {
    margin: 0 auto 150px;
    padding: 0 15px;
  }
}
.sectionFooterTop {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}
.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 2vh;

  @media (max-width: 575px) {
    margin-top: 0;
    margin-bottom: 130px !important;
  }
}
.FooterTop {
  background-size: cover;
}
/* A bar on top of light sections */
.abtsecrow {
  /* padding: 0 0 80px; */
}
.abtsecrow svg path {
  fill: #fff;
}
.dnerow {
  margin: 40px auto 100px;
  max-width: 100%;
  @media (max-width: 575px) {
    margin: 0px auto 100px;
  }
}
.dnerow h2,
.tratrow h1 {
  font-family: 'Stardos Stencil', cursive;
  text-align: center;
  font-size: 45px;
  color: #232323;
  margin: 0 0 40px;
  line-height: 60px;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 34px;
    line-height: 40px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
}
.tratrow {
  margin: 30px auto;
  max-width: 100%;
  @media (max-width: 575px) {
    margin-top: 0;
  }
}
.hatwrow {
  margin-bottom: 200px;
  max-width: 100%;
  @media (max-width: 575px) {
    margin-bottom: 80px;
  }
}
.footlast svg path {
  fill: #002435;
}
.testislider {
  margin-bottom: 60px;
}
